import React, {useState} from "react";
import {css, cx} from "@panda/css";

import {Facebook2Icon} from "./icons/Facebook2Icon";
import {InstagramIcon} from "./icons/InstagramIcon";
import {LinkedInIcon} from "./icons/LinkedInIcon";
import {HamburgerTrigger} from "./HamburgerTrigger";
import {Logo} from "./Logo";

import logoDM from "../images/logo_dm.svg";
import logoRP from "../images/logo_dm.svg";

export interface INavLink {
    text: string;
    url: React.RefObject<HTMLTableSectionElement>;
}

interface IProps {
    links: INavLink[];
    scrollTo: (duration: number, refElem: React.RefObject<HTMLTableSectionElement>) => (e: React.MouseEvent) => void;
}

export const NavigationMobile = (props: IProps) => {
    const [navOpened, setNavOpened] = useState(false);
    const navMobileClassName = cx(navMobile, "nav-mobile", navOpened && "opened");
    const NAV_MAIN_COLOR = "#0c284f";
    const customStyles = {
        navBarColor: "#fff",
        fontColor: NAV_MAIN_COLOR,
        asidePanelColor: NAV_MAIN_COLOR,
        asideFontColor: "#fff",
        triggerColor: NAV_MAIN_COLOR,
        invertedTriggerColor: "#fff"
    };

    const logo = {
        logoSrc: logoDM,
        url: "/",
        height: 60,
        width: 160
    };

    const additionalElement = () => {
        return (
            <>
                <div className={socialIconHolder}>
                    <a href="https://www.facebook.com/groups/deweloper.i.marketing/" target="_blank">
                        <Facebook2Icon height={30} width={30} mainColor="#fff" />
                    </a>

                    <a href="https://www.instagram.com/rynekpierwotny/" target="_blank">
                        <InstagramIcon height={30} width={30} mainColor="#fff" />
                    </a>

                    <a href="https://www.linkedin.com/company/3503462/ " target="_blank">
                        <LinkedInIcon height={30} width={30} mainColor="#fff" />
                    </a>
                </div>

                <div className={logoWrapper}>
                    <p>Wydawcą magazynu jest portal</p>
                    <a href="https://rynekpierwotny.pl" target="_blank">
                        <img src={logoRP} width={200} height={44} alt="logo_rynek_pierwotny" />
                    </a>
                </div>
            </>
        );
    };

    return (
        <>
            <div className={cx(headerMobileWrapper, "header-mobile")} style={{backgroundColor: customStyles.navBarColor, color: customStyles.fontColor}}>
                <Logo {...logo} />
                <HamburgerTrigger onClick={() => setNavOpened(!navOpened)} opened={navOpened} />
                <nav className={navMobileClassName} style={{color: customStyles.asidePanelColor, backgroundColor: customStyles.asidePanelColor}}>
                    {props.links.map((link, i) => (
                        <div className={navLinkMobile} style={{color: customStyles.asideFontColor}} key={i} onClick={props.scrollTo(300, link.url)}>
                            <div onClick={() => setNavOpened(!navOpened)}>{link.text}</div>
                        </div>
                    ))}

                    {additionalElement()}
                </nav>
            </div>
        </>
    );
};

const headerMobileWrapper = css({
    fontSize: "25px",
    fontWeight: 300,
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    backgroundColor: "#9069c0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "background-color 300ms ease",
    zIndex: 120,

    "@media screen and (min-width: 1024px)": {
        display: "none"
    }
});

export const navMobile = css({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: "-100%",
    width: "100%",
    height: "100vh",
    padding: "40px 0 0 0",
    transition: "all 0.5s",
    backgroundColor: "#37474f",
    zIndex: 120,

    "&.opened": {
        right: 0
    },

    "@media screen and (min-width: 1025px)": {
        display: "none"
    }
});

export const navLinkMobile = css({
    display: "block",
    borderBottom: "1px solid #fff",
    color: "white",
    textTransform: "uppercase",
    textDecoration: "none",
    cursor: "pointer",

    "& > div": {
        padding: "16px 30px"
    }
});

const logoWrapper = css({
    color: "white",
    padding: "16px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    whiteSpace: "nowrap"
});

const socialIconHolder = css({
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    justifyContent: "space-evenly",
    color: "white",
    padding: "16px 30px",
    borderBottom: "1px solid #fff",

    "&.svg-icon": {
        marginRight: "20px"
    }
});
