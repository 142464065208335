import React from "react";
import {css} from "@panda/css";

interface ILogoProps {
    logoSrc: string;
    url: string;
    height: number;
    width: number;
}

export const Logo = (props: ILogoProps) => (
    <a className={css({padding: "10px"})} href={props.url}>
        <div className={imageStyles} style={{backgroundImage: `url("${props.logoSrc}")`, width: props.width, height: props.height}} />
    </a>
);

const imageStyles = css({
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain"
});
