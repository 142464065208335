import React from "react";
import {cx} from "@panda/css";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const Facebook2Icon = (props: IProps) => {
    return (
        <svg className={cx(props.className, "svg-icon")} width={props.width || "28.16"} height={props.height || "28.16"} viewBox="0 0 28.16 28.16">
            <path
                d="M14.08,0A14.08,14.08,0,1,0,28.16,14.08,14.08,14.08,0,0,0,14.08,0Zm3.59,8.52H15.83c-.65,0-.78.26-.78.94v1.63h2.62L17.41,14H15.05v8.56H11.66V14H9.89v-2.9h1.77V8.81c0-2.14,1.14-3.26,3.66-3.26h2.35Z"
                fill={props.mainColor || "#0c284f"}
            />
        </svg>
    );
};
