import React from "react";
import {css} from "@panda/css";

interface IProps {
    width?: string;
    height?: string;
    color?: string;
}

export const ElementLoader = (props: IProps) => {
    return (
        <div className={loaderHolder} style={{width: props.width || "50px", height: props.height || "50px"}}>
            <div className={loader} style={{borderTopColor: props.color || "#0C284F"}} />
        </div>
    );
};

const loaderHolder = css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const loader = css({
    display: "inline-block",
    position: "relative",
    width: "50px",
    height: "50px",

    "&:before": {
        content: '""',
        boxSizing: "border-box",
        position: "absolute",
        top: "50%",
        left: "50%",
        borderRadius: "50%",
        animation: "spinner 0.6s linear infinite",
        width: "24px",
        height: "24px",
        marginTop: "calc(-24px / 2)",
        marginLeft: "calc(-24px / 2)",
        border: "2px solid transparent"
    }
});
