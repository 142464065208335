import React from "react";
import {cx} from "@panda/css";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    className?: string;
}

export const EnvelopeIcon = (props: IProps) => {
    return (
        <svg width={props.width || 21} height={props.height || 17} className={cx(props.className, "svg-icon")} viewBox="0 0 21 17">
            <path
                fill={props.mainColor || "#fff"}
                fillRule="evenodd"
                d="M18.1053221,2.30926389e-14 C19.4436328,2.30926389e-14 20.5593658,0.969802667 20.7867741,2.24366095 L20.8292118,2.24310329 L20.8291317,13.6190476 C20.8291317,15.3863193 19.3919216,16.8235294 17.6246499,16.8235294 L3.20448179,16.8235294 C1.4380112,16.8235294 5.38236122e-13,15.3863193 5.38236122e-13,13.6190476 L8.01120454e-05,2.24310329 L0.0423575032,2.24366095 C0.269765812,0.969802667 1.38549884,2.30926389e-14 2.72380952,2.30926389e-14 L18.1053221,2.30926389e-14 Z M1.602,4.582 L1.6022409,13.6190476 C1.6022409,14.5026835 2.32084594,15.2212885 3.20448179,15.2212885 L17.6246499,15.2212885 C18.5082857,15.2212885 19.2268908,14.5026835 19.2268908,13.6190476 L19.226,4.583 L12.5720633,9.24116471 C11.9271613,9.69299664 11.1709036,9.91891261 10.4146459,9.91891261 C9.65838824,9.91891261 8.90213053,9.69299664 8.25722857,9.24116471 L1.602,4.582 Z M18.1053221,1.6022409 L2.72380952,1.6022409 C2.13677473,1.6022409 1.65367396,2.05550875 1.60608801,2.63046366 L9.17611373,7.92892941 C9.91634902,8.44725434 10.9121417,8.44725434 11.6531782,7.92892941 L19.221,2.63 L19.2202976,2.60176956 C19.1592976,2.04050007 18.6825561,1.6022409 18.1053221,1.6022409 Z"
            />
        </svg>
    );
};
