import React from "react";
import {css, cx} from "@panda/css";

import {ArrowWithOrientationIcon} from "../../icons/ArrowWithOrientationIcon";

interface IProps {
    className?: string;
    extraClassName?: string; // prop className is used by ReactSlick and overwrites props passed manually
    onClick?(): void;
    orientation: "right" | "left";
}

export const SliderArrow = (props: IProps) => {
    const {onClick, orientation} = props;
    const className = (props.className && props.className.replace(/slick/g, ""), props.extraClassName) || "";

    return (
        <div className={cx(slideArrowIcon, orientation, className)} onClick={onClick}>
            <ArrowWithOrientationIcon orientation={orientation} />
        </div>
    );
};

export const slideArrowIcon = css({
    width: "60px",
    height: "120px",
    transition: "background-color 150ms ease-out",
    color: "#fff",
    overflow: "hidden",
    cursor: "pointer",
    zIndex: 3,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    transform: "translate(0%, -50%)",

    "&::after": {
        width: "120px",
        height: "120px",
        content: '""',
        position: "absolute",
        transition: "background-color 150ms ease-out",
        backgroundColor: "rgba(from #fff r g b / 0.45)",
        borderRadius: "50%",
        right: 0
    },

    "&.right": {
        right: 0,
        left: "auto",
        paddingLeft: 0,

        "&::after": {
            right: "auto",
            left: 0
        }
    },

    "&:hover": {
        "&::after": {
            backgroundColor: "rgba(from #fff r g b / 0.6)"
        }
    }
});
