import type {ElementType, PropsWithChildren} from "react";
import {css} from "@panda/css";
import type {SystemStyleObject} from "@panda/types";

interface IProps extends Partial<Omit<HTMLAnchorElement, "as" | "children" | "className">> {
    as?: ElementType;
    className?: SystemStyleObject;
}

export const Button = (props: PropsWithChildren<IProps>) => {
    const {as, children, className, ...rest} = props;

    const Element = as || "button";

    return (
        <Element className={css(button, className)} {...rest}>
            {children}
        </Element>
    );
};

const button = css.raw({
    border: "none",
    borderRadius: "5px",
    background: "linear-gradient(244.81deg, #5a054e 0%, #ff4755 98.59%, #001349 100%)",
    padding: "10px 30px",
    height: "45px"
});
