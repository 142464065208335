import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {css} from "@panda/css";

import {isEmpty} from "@pg-mono/nodash";

import {getArticles, IArticle} from "../api/get_articles";
import {getIssues, IIssue} from "../api/get_issues";
import {getPartners, IPartner} from "../api/get_partners";
import {getPartnersOpinions, IPartnerOpinion} from "../api/get_partners_opinions";
import {AboutIssue} from "../components/AboutIssue";
import {Archive} from "../components/Archive";
import {Articles} from "../components/Articles";
import {ElementLoader} from "../components/common/ElementLoader";
import {Contact} from "../components/Contact";
import {HeroSection} from "../components/HeroSection";
import {LatestEdition} from "../components/LatestEdition";
import {Navigation} from "../components/Navigation";
import {Opinion} from "../components/Opinion";
import {FacebookPixel} from "../tracking/fb/utils/track_facebook_piksel";
import {initGoogleTagManager} from "../tracking/gtm/utils/init_google_tag_manager";
import {initGtagGoogleTagManager} from "../tracking/gtm/utils/init_gtag_google_tag_manager";

export const DeveloperMarketing = () => {
    const refAboutIssue = useRef<HTMLTableSectionElement>(null);
    const refLatestEdition = useRef<HTMLTableSectionElement>(null);
    const refArchive = useRef<HTMLTableSectionElement>(null);
    const refArticles = useRef<HTMLTableSectionElement>(null);
    const refContact = useRef<HTMLTableSectionElement>(null);

    const refElems = useMemo(() => ({refAboutIssue, refLatestEdition, refArchive, refArticles, refContact}), []);

    const scrollTo = useCallback(
        (_duration: number, refElem: React.RefObject<HTMLTableSectionElement>) => (e: React.MouseEvent) => {
            e.preventDefault();

            refElem.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
        },
        []
    );

    const [data, setData] = useState<{
        partners: IPartner[] | null;
        issues: IIssue[] | null;
        opinions: IPartnerOpinion[] | null;
        articles: IArticle[] | null;
    }>({
        partners: null,
        issues: null,
        opinions: null,
        articles: null
    });

    useEffect(() => {
        (async () => {
            const [partnersData, issuesData, opinionsData, articlesData] = await Promise.all([
                getPartners(),
                getIssues(),
                getPartnersOpinions(),
                getArticles()
            ]);

            setData({
                partners: partnersData,
                issues: issuesData,
                opinions: opinionsData,
                articles: articlesData
            });
        })();
    }, []);

    // GTM/Analytics
    useEffect(() => {
        initGoogleTagManager("GTM-NGBDVBX");

        initGtagGoogleTagManager("UA-12360009-28"); // we intentionally keep both gTag and GTM

        FacebookPixel.init("285371446009565");
    }, []);

    return (
        <div className={mainContainer}>
            <Navigation refElems={refElems} scrollTo={scrollTo} />

            <HeroSection partners={data.partners} />

            {data.issues ? (
                !isEmpty(data.issues) && <AboutIssue refElem={refAboutIssue} issue={data.issues[0]} />
            ) : (
                <ElementLoader height="438px" width="100%" />
            )}

            {data.issues ? (
                !isEmpty(data.issues) && <LatestEdition refElem={refLatestEdition} issue={data.issues[0]} />
            ) : (
                <ElementLoader height="472px" width="100%" />
            )}

            {data.issues ? !isEmpty(data.issues) && <Archive refElem={refArchive} issues={data.issues} /> : <ElementLoader height="701px" width="100%" />}

            {data.articles ? (
                !isEmpty(data.articles) && <Articles articles={data.articles} refElem={refArticles} />
            ) : (
                <ElementLoader height="632px" width="100%" />
            )}

            {data.opinions ? !isEmpty(data.opinions) && <Opinion opinions={data.opinions} /> : <ElementLoader height="500px" width="100%" />}

            <Contact refElem={refContact} />
        </div>
    );
};

const mainContainer = css({
    fontSize: "14px",
    maxWidth: "100%"
});
