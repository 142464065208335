import React, {useEffect, useState} from "react";
import {css, cx} from "@panda/css";

import {IPartnerOpinion} from "../api/get_partners_opinions";
import {ContentContainer} from "./common/ContentContainer";
import {SectionTitle} from "./common/SectionTitle";
import {BasicSlider} from "./common/slider/Slider";
import {CloseIcon} from "./icons/CloseIcon";
import {Modal} from "./Modal";

interface IProps {
    opinions: IPartnerOpinion[];
}

export const Opinion = (props: IProps) => {
    const mobileBreakpoint = 1024;
    const characterCounter = 150;

    const [isModalOpen, setModalOpen] = useState(false);
    const [openedOpinion, setOpenedOpinion] = useState(0);
    const [slidesCounter, setSlidesCounter] = useState(window.innerWidth < mobileBreakpoint ? 1 : 3);

    const sliderConfig = {
        infinite: true,
        slidesToShow: slidesCounter,
        slidesToScroll: 1
    };

    const modalFullContent = {
        backgroundColor: "#fff",
        overflow: "hidden",
        outline: "none",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        borderRadius: 0,
        height: "auto",
        width: "390px"
    };

    const resizeHandler = () => {
        if (window.innerWidth < mobileBreakpoint) {
            return setSlidesCounter(1);
        }

        return setSlidesCounter(3);
    };

    const truncateString = (text: string, count: number) => {
        if (text.length > count) {
            return text.slice(0, count) + '..."';
        }
        return text;
    };

    const openOpinion = (opinion: string, index: number) => {
        if (opinion.length < characterCounter) {
            return;
        }
        setOpenedOpinion(index);
        setModalOpen(true);
    };

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    const renderModalContent = (index: number) => {
        return (
            <>
                <div className={modalHeader} onClick={() => setModalOpen(false)}>
                    <CloseIcon className={closeButton} color="#000" width={15} height={15} />
                </div>

                <div className={modalOpinionBox}>
                    <div className={opinionImgHolder}>
                        <img
                            className={opinionImage}
                            src={props.opinions[index].partner.logo}
                            alt={props.opinions[index].partner.name}
                            height={120}
                            width={120}
                        />
                    </div>

                    <p className={cx(developerOpinionText, "modal")}>
                        <span>{props.opinions[index].text}</span>
                    </p>
                </div>
            </>
        );
    };

    const renderOpinionBoxes = () =>
        props.opinions.map((element, index) => (
            <div
                className={opinionBoxWrapper}
                key={index}
                onClick={() => openOpinion(element.text, index)}
                style={{cursor: element.text.length > characterCounter ? "pointer" : "auto"}}
            >
                <div className={opinionImgHolder}>
                    <img className={opinionImage} src={element.partner.logo} alt={element.partner.name} height={120} width={120} />
                </div>

                <p className={developerOpinionText}>{truncateString(element.text, characterCounter)}</p>
            </div>
        ));

    return (
        <section className={container}>
            <SectionTitle title="Opinie Deweloperów" />

            <ContentContainer className={contentContainerCustom}>
                <BasicSlider sliderConfig={sliderConfig}>{renderOpinionBoxes()}</BasicSlider>
            </ContentContainer>

            <Modal modalState={isModalOpen} onModalClose={() => setModalOpen(false)} type="window" contentStyles={modalFullContent}>
                {renderModalContent(openedOpinion)}
            </Modal>
        </section>
    );
};

const container = css({
    marginTop: "30px",
    marginBottom: "30px",
    position: "relative"
});

const contentContainerCustom = css.raw({
    maxWidth: "1200px",

    "@media screen and (max-width: 1280px)": {
        maxWidth: "1200px" // override breakpoint from ContentContainer
    },

    "& .slide": {
        "&:focus": {
            outline: "none !important"
        }
    },

    "& .slick-slider": {
        "& .left, & .right": {
            zIndex: 2,

            "&::after": {
                backgroundColor: "rgba(51, 51, 51, 0.45) !important"
            }
        }
    }
});

const opinionBoxWrapper = css({
    height: "296px",
    width: "330px",
    border: "1px solid #dddddd",
    borderRadius: "10px",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    padding: "10px 30px",
    margin: "30px auto",
    outline: "none",

    "& > div": {
        width: "120px",
        height: "120px"
    },

    "& ::after": {
        content: "url(../images/quote.svg)",
        position: "absolute",
        top: "-25px",
        left: "-30px",
        height: "70px",
        width: "60px",

        "@media screen and (max-width: 1024px)": {
            left: "-10px"
        }
    },

    "@media screen and (max-width: 1024px)": {
        width: "300px",
        height: "250px",
        padding: "10px"
    }
});

const modalOpinionBox = css({
    minHeight: "296px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 30px",
    margin: "0 auto",
    height: "auto",

    "& > div": {
        width: "120px",
        height: "120px"
    }
});

const opinionImgHolder = css({
    marginBottom: "10px"
});

const opinionImage = css({
    border: "1px solid #979797",
    borderRadius: "50%",
    objectFit: "contain",
    height: "100% !important",
    marginBottom: "20px",

    "@media screen and (max-width: 1024px)": {
        marginBottom: "0"
    }
});

const developerOpinionText = css({
    color: "#0c284f",
    fontSize: "18px",
    letterSpacing: "0",
    lineHeight: "25px",
    textAlign: "center",

    "&.modal": {
        maxHeight: "300px",
        overflow: "auto"
    }
});

const modalHeader = css({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end"
});

const closeButton = css({
    marginLeft: "auto",
    marginTop: "10px",
    marginRight: "10px",
    cursor: "pointer"
});
