import React from "react";
import {FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import {css} from "@panda/css";

interface IProps {
    img: string;
    title: string;
    lead: string;
    articleLink: string;
    breakpoint?: number;
}

export const ArticleBox = (props: IProps) => {
    const truncateString = (text: string, count: number) => {
        if (text.length > count) {
            return text.slice(0, count) + "...";
        }
        return text;
    };

    return (
        <div className={boxHolder}>
            <a className={boxLink} href={props.articleLink} target="_blank">
                <img className={boxImg} src={props.img} alt={props.title} />

                <div className={contentHolder}>
                    <div className={titleWrapper}>
                        <h3 className={title}>{truncateString(props.title, 50)}</h3>
                    </div>

                    <div className={leadWrapper}>
                        <p className={lead}>{truncateString(props.lead, 190)}</p>
                    </div>

                    <div className={socialHolder}>
                        <FacebookShareButton url={props.articleLink}>
                            <FacebookIcon size={30} round path="" />
                        </FacebookShareButton>

                        <TwitterShareButton url={props.articleLink}>
                            <TwitterIcon size={30} round path="" />
                        </TwitterShareButton>

                        <LinkedinShareButton url={props.articleLink}>
                            <LinkedinIcon size={30} round path="" />
                        </LinkedinShareButton>
                    </div>
                </div>
            </a>
        </div>
    );
};

const boxHolder = css({
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.1)",
    maxWidth: "375px",

    "@media (max-width: 1250px)": {
        maxWidth: "300px"
    }
});

const boxImg = css({
    width: "375px",
    height: "320px",

    "@media (max-width: 1250px)": {
        width: "300px",
        height: "240px"
    }
});

const boxLink = css({
    textDecoration: "none",

    "&:hover, &:focus": {
        textDecoration: "none"
    }
});

const contentHolder = css({
    padding: "0 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
});

const titleWrapper = css({
    height: "72px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0"
});

const title = css({
    margin: "0",
    color: "#0c284f",
    fontSize: "30px",
    fontWeight: "500",
    letterSpacing: "0.5px",
    textAlign: "center",
    padding: "0",

    "@media (max-width: 1250px)": {
        fontSize: "24px"
    }
});

const leadWrapper = css({
    height: "150px",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px"
});

const lead = css({
    margin: "0",
    padding: "0",
    color: "#757575",
    fontSize: "20px",
    fontWeight: "300",
    letterSpacing: "0.33px",
    lineHeight: "30px",
    textAlign: "center",

    "@media (max-width: 1250px)": {
        fontSize: "16px"
    }
});

const socialHolder = css({
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
});
