import React from "react";
import {cx} from "@panda/css";

interface IProps {
    height?: number;
    width?: number;
    className?: string;
    mainColor?: string;
}

export const MarkerOutlineIcon = (props: IProps) => {
    return (
        <svg className={cx(props.className, "svg-icon")} width={props.width || "40"} height={props.height || "40"} viewBox="5 6 50 50">
            <defs>
                <mask id="a" x="0" y="0.25" width="50" height="50" maskUnits="userSpaceOnUse">
                    <path
                        d="M29.76,7.5A19.78,19.78,0,0,1,49.52,27.26c0,14.76-18,26.93-18.75,27.43a1.77,1.77,0,0,1-1,.31,1.79,1.79,0,0,1-1-.31C28,54.19,10,42,10,27.26A19.78,19.78,0,0,1,29.76,7.5Zm0,3.64A16.14,16.14,0,0,0,13.64,27.26c0,11,12.44,21,16.12,23.69,3.68-2.71,16.12-12.71,16.12-23.69A16.14,16.14,0,0,0,29.76,11.14ZM30,18.05a8.86,8.86,0,1,1-8.86,8.85A8.86,8.86,0,0,1,30,18.05Zm0,3.63a5.22,5.22,0,1,0,5.22,5.22A5.22,5.22,0,0,0,30,21.68Z"
                        fill={props.mainColor || "#000"}
                        fillRule="evenodd"
                    />
                </mask>
            </defs>
            <rect width="60" height="60" fill="none" />
            <path
                d="M29.76,7.5A19.78,19.78,0,0,1,49.52,27.26c0,14.76-18,26.93-18.75,27.43a1.77,1.77,0,0,1-1,.31,1.79,1.79,0,0,1-1-.31C28,54.19,10,42,10,27.26A19.78,19.78,0,0,1,29.76,7.5Zm0,3.64A16.14,16.14,0,0,0,13.64,27.26c0,11,12.44,21,16.12,23.69,3.68-2.71,16.12-12.71,16.12-23.69A16.14,16.14,0,0,0,29.76,11.14ZM30,18.05a8.86,8.86,0,1,1-8.86,8.85A8.86,8.86,0,0,1,30,18.05Zm0,3.63a5.22,5.22,0,1,0,5.22,5.22A5.22,5.22,0,0,0,30,21.68Z"
                fill={props.mainColor || "#000"}
                fillRule="evenodd"
            />
            <g mask="url(#a)">
                <rect y="0.25" width="60" height="60" fill={props.mainColor || "#000"} />
            </g>
        </svg>
    );
};
