import React from "react";
import {css} from "@panda/css";

import {CheckIcon} from "../icons/CheckIcon";

interface IProps {
    items: string[];
    isDark?: boolean;
}

export const Checklist = (props: IProps) => {
    return (
        <ul className={list}>
            {props.items.map((item) => (
                <li className={listItem} style={{color: props.isDark ? "#757575" : "#fff"}} key={item}>
                    <CheckIcon className={listItemIcon} mainColor={props.isDark ? "#757575" : "#fff"} /> {item}
                </li>
            ))}
        </ul>
    );
};

const list = css({
    listStyleType: "none",
    padding: 0,

    "@media (max-width: 1025px)": {
        marginBottom: "20px"
    }
});

const listItem = css({
    display: "flex",
    alignItems: "center",
    fontSize: "25px",
    letterSpacing: " 0.42px",
    marginBottom: "25px",

    "@media (max-width: 1700px)": {
        fontSize: "2rem",
        marginBottom: "20px"
    },

    "@media (max-width: 1350px)": {
        fontSize: "1.6rem",
        marginBottom: "15px"
    }
});

const listItemIcon = css({
    width: "32px",
    height: "30px",
    marginRight: "20px",
    flexShrink: 0,

    "@media (max-width: 1700px)": {
        marginRight: "15px",
        width: "27px",
        height: "25px"
    },

    "@media (max-width: 1350px)": {
        marginRight: "10px",
        width: "22px",
        height: "20px"
    }
});
