import React from "react";
import {css} from "@panda/css";

import {SectionTitle} from "./common/SectionTitle";
import {EnvelopeIcon} from "./icons/EnvelopeIcon";
import {MarkerOutlineIcon} from "./icons/MarkerOutlineIcon";
import {PhoneIcon} from "./icons/PhoneIcon";

interface IOwnProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
}

export const Contact = (props: IOwnProps) => {
    return (
        <footer className={footer} ref={props.refElem}>
            <SectionTitle title="Kontakt" lineColor="#fff" />

            <div className={contentHolder}>
                <div className={contactDataWrapper}>
                    <div className={infoHolder}>
                        <div className={iconHolder}>
                            <MarkerOutlineIcon mainColor="#fff" width={50} height={50} />
                        </div>

                        <div className={labelWithValueHolder}>
                            <span className={infoBold}>Nasz adres</span>
                            <a className={contactLink} href="https://goo.gl/maps/PhK5mC6A4ya6fw9p6" target="_blank">
                                ul. Naruszewicza 27 lok. 101
                            </a>
                        </div>
                    </div>

                    <div className={infoHolder}>
                        <div className={iconHolder}>
                            <EnvelopeIcon mainColor="#fff" width={45} height={45} />
                        </div>

                        <div className={labelWithValueHolder}>
                            <span className={infoBold}>Email</span>
                            <a className={contactLink} href="mailto:pr@rynekpierwotny.pl">
                                pr@rynekpierwotny.pl
                            </a>
                        </div>
                    </div>

                    <div className={infoHolder}>
                        <div className={iconHolder}>
                            <PhoneIcon mainColor="#fff" width={45} height={45} />
                        </div>

                        <div className={labelWithValueHolder}>
                            <span className={infoBold}>Numer telefonu</span>
                            <a className={contactLink} href="tel:228256071">
                                +48 (22) 825 60 71
                            </a>
                        </div>
                    </div>
                </div>

                <div style={{fontWeight: 300}}>
                    Wydawca serwisu www.rynekpierwotny.pl jest Property Group Sp. z o.o. ul. A. Naruszewicza 27/101, 02-627 Warszawa REGON 141961782, NIP
                    5213538080. Spółka jest zarejestrowana przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru
                    Sądowego pod numerem KRS 0000335123. Kapitał zakładowy w wysokości 50 000 zł.
                </div>
            </div>
        </footer>
    );
};

const footer = css({
    marginTop: "30px",
    padding: "30px 20px 50px 20px",
    background: "linear-gradient(180deg, #113669 0%, #0c284f 100%)",
    color: "#fff"
});

const contentHolder = css({
    margin: "0 auto",
    maxWidth: "1400px"
});

const contactDataWrapper = css({
    display: "flex",
    justifyContent: "space-around",
    fontSize: "1.4rem",
    marginBottom: "70px",

    "@media (max-width: 990px)": {
        marginBottom: "10px",
        flexDirection: "column",
        alignItems: "center"
    }
});

const labelWithValueHolder = css({
    display: "flex",
    flexDirection: "column"
});

const infoBold = css({
    fontWeight: "400"
});

const contactLink = css({
    fontSize: "2.4rem",
    color: "#fff",

    "&:hover, &:focus": {
        textDecoration: "none",
        color: "#e0e0e0"
    },

    "@media (max-width: 990px)": {
        fontSize: "2rem"
    }
});

const infoHolder = css({
    display: "flex",
    alignItems: "center",
    fontSize: "3rem",
    fontWeight: "300",

    "@media (max-width: 990px)": {
        fontSize: "2.6rem",
        width: "310px",
        marginBottom: "40px"
    }
});

const iconHolder = css({
    width: "60px",
    height: "60px",
    marginRight: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 990px)": {
        marginRight: "10px"
    }
});
