import type {PropsWithChildren} from "react";
import {css} from "@panda/css";
import type {SystemStyleObject} from "@panda/types";

import {Button} from "./Button";

interface IProps extends Partial<Omit<HTMLAnchorElement, "className" | "children">> {
    className?: SystemStyleObject;
}

export const DownloadButton = (props: PropsWithChildren<IProps>) => {
    const {children, className, ...rest} = props;

    return (
        <Button as="a" className={css.raw(downloadButton, className)} {...rest}>
            {children}
        </Button>
    );
};

const downloadButton = css.raw({
    height: "78px",
    width: "190px",
    left: "50%",
    transform: "translate(-50%, 0)",
    bottom: "-30px",
    textTransform: "uppercase",
    color: "#fff",
    fontSize: "35px",
    fontWeight: "500",
    letterSpacing: "0.58px",
    lineHeight: "52px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
    zoom: "0.8",
    position: "absolute", // Assuming `psa` translates to "position: absolute"

    "&:hover, &:focus, &:active": {
        textDecoration: "none",
        color: "#fff"
    },

    "@media screen and (max-width: 1280px)": {
        height: "50px",
        width: "150px",
        fontSize: "20px"
    }
});
