import type {CSSProperties, PropsWithChildren} from "react";
import {css} from "@panda/css";
import type {SystemStyleObject} from "@panda/types";

interface IProps {
    className?: SystemStyleObject;
    style?: CSSProperties;
}

export const Title = (props: PropsWithChildren<IProps>) => {
    return (
        <h3 className={css(title, props.className)} style={props.style}>
            {props.children}
        </h3>
    );
};

export const title = css.raw({
    margin: "0 0 30px 0",
    fontSize: "40px",
    fontWeight: "400",
    letterSpacing: "0.67px",

    "@media (max-width: 1700px)": {
        fontSize: "35px"
    },

    "@media (max-width: 1350px)": {
        fontSize: "30px",
        marginBottom: "20px"
    }
});
