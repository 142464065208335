import type {PropsWithChildren} from "react";
import {css} from "@panda/css";
import type {SystemStyleObject} from "@panda/types";

interface IProps {
    className?: SystemStyleObject;
}

export const ContentContainer = (props: PropsWithChildren<IProps>) => {
    return <div className={css(contentContainer, props.className)}>{props.children}</div>;
};

const contentContainer = css.raw({
    maxWidth: "1064px",
    maxHeight: "620px",
    margin: "0 auto",

    "& .left:not(.svg-icon)": {
        position: "absolute !important",
        left: "0 !important"
    },

    "& .slick-slide": {
        display: "flex !important",
        justifyContent: "center !important"
    },

    "@media screen and (max-width: 1280px)": {
        maxWidth: "800px"
    }
});
