import React, {useEffect, useState} from "react";
import {css} from "@panda/css";

import {isEmpty} from "@pg-mono/nodash";

import {IIssue} from "../api/get_issues";
import {ContentContainer} from "./common/ContentContainer";
import {DownloadButton} from "./common/DownloadButton";
import {ElementLoader} from "./common/ElementLoader";
import {SectionTitle} from "./common/SectionTitle";
import {BasicSlider} from "./common/slider/Slider";

import backgroundWave from "../images/background_wave.png";

interface IProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
    issues: IIssue[] | null;
}
export const Archive = (props: IProps) => {
    const mobileBreakpoint = 1024;
    const [slidesCounter, setSlidesCounter] = useState(window.innerWidth < mobileBreakpoint ? 1 : 3);

    const sliderConfig = {
        infinite: true,
        slidesToShow: slidesCounter,
        slidesToScroll: 1
    };

    const resizeHandler = () => {
        if (window.innerWidth < mobileBreakpoint) {
            return setSlidesCounter(1);
        }

        return setSlidesCounter(3);
    };

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    const slides = (props.issues || []).map((item) => {
        return (
            <div className={issueHolder} key={item.number}>
                <div className={imgWrapper}>
                    <img src={item.cover_image} width="100%" height="auto" alt="archiwum" />
                    <DownloadButton href={item.file} target="_blank">
                        Pobierz
                    </DownloadButton>
                </div>

                <div className={textContainer}>
                    <p className={title}>Deweloper&Marketing</p>
                    <p className={issue}>{item.number}</p>
                </div>
            </div>
        );
    });

    return (
        <section className={archiveSection} ref={props.refElem} style={{backgroundImage: `url("${backgroundWave}")`}}>
            <SectionTitle title="Archiwum" lineColor="#fff" />

            <ContentContainer>
                {!isEmpty(props.issues) ? (
                    <BasicSlider sliderConfig={sliderConfig}>{slides}</BasicSlider>
                ) : (
                    <ElementLoader height="588px" width="100%" color="#fff" />
                )}
            </ContentContainer>
        </section>
    );
};

const archiveSection = css({
    padding: "25px 0 20px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "auto",
    margin: "30px 0 0 0",
    position: "relative",
    color: "#fff",

    "@media screen and (min-width: 1024px)": {
        marginTop: "75px"
    }
});

const issueHolder = css({
    position: "relative",
    margin: "0 auto",
    height: "584px",
    width: "332px",

    "@media screen and (max-width: 1280px)": {
        width: "250px",
        height: "465px"
    }
});

const imgWrapper = css({
    position: "relative",
    width: "332px",
    margin: "0 auto",

    "@media screen and (max-width: 1280px)": {
        width: "250px"
    }
});

const textContainer = css({
    textAlign: "center",
    marginTop: "30px"
});

const title = css({
    fontSize: "30px",
    fontWeight: "500",
    letterSpacing: "0.5px",
    lineHeight: "45px",

    "@media screen and (max-width: 1280px)": {
        fontSize: "20px",
        lineHeight: "30px"
    }
});

const issue = css({
    fontSize: "25px",
    letterSpacing: "0.42px",
    lineHeight: "37px",
    textTransform: "capitalize",

    "@media screen and (max-width: 1280px)": {
        fontSize: "18px",
        lineHeight: "26px"
    }
});
