import React from "react";
import {cx} from "@panda/css";

interface IProps {
    height?: number;
    width?: number;
    mainColor?: string;
    accentColor?: string;
    className?: string;
}

export const RynekPierwotnyLogoIcon = (props: IProps) => {
    return (
        <svg className={cx(props.className, "svg-icon")} width={props.width || "196px"} height={props.height || "60px"} viewBox="0 0 196 60" version="1.1">
            <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#EBFF00" />
            <path d="M71.0868 16.4768H75.3671V13.793H68.2754V26.1751H71.0868V16.4768Z" fill="#23232D" />
            <path
                d="M95.9299 13.5391C94.3095 13.5391 93.0174 14.3247 92.2332 15.6911V13.793H89.3965V26.1751H92.2332V18.9838C92.4099 17.3129 93.5744 16.2495 95.2466 16.2495C97.0704 16.2495 98.2587 17.5121 98.2587 19.465V26.1751H101.065V19.1115C101.07 15.6659 99.1215 13.5391 95.9299 13.5391Z"
                fill="#23232D"
            />
            <path
                d="M108.911 26.4291C111.924 26.4291 114.305 24.7329 114.736 22.0983H111.955C111.703 23.2641 110.486 24.0217 108.891 24.0217C106.84 24.0217 105.398 22.6791 105.144 20.6547H114.842C114.868 20.3741 114.893 19.8452 114.893 19.5913C114.893 16.1471 112.209 13.5391 108.86 13.5391C105.29 13.5391 102.479 16.3253 102.479 19.9462C102.484 23.6176 105.296 26.4291 108.911 26.4291ZM108.734 15.9198C110.43 15.9198 111.748 17.059 111.899 18.6036H105.296C105.752 16.8822 107.044 15.9198 108.74 15.9198H108.734Z"
                fill="#23232D"
            />
            <path
                d="M75.4936 33.5703C73.4931 33.5703 71.9737 34.4822 71.0871 36.0521V33.8242H68.2812V50.5372H71.0871V43.9786C71.9737 45.5484 73.4931 46.4603 75.4936 46.4603C78.8101 46.4603 81.4196 43.5984 81.4196 40.0279C81.4196 36.4575 78.8101 33.5703 75.4936 33.5703ZM74.8862 43.7765C72.7341 43.7765 71.1123 42.1548 71.1123 40.0279C71.1123 37.8759 72.7341 36.2541 74.8862 36.2541C75.8837 36.2541 76.8404 36.6504 77.5458 37.3557C78.2511 38.0611 78.6474 39.0178 78.6474 40.0153C78.6474 41.0129 78.2511 41.9695 77.5458 42.6749C76.8404 43.3803 75.8837 43.7765 74.8862 43.7765Z"
                fill="#23232D"
            />
            <path d="M85.6962 33.8242H82.8848V46.2078H85.6962V33.8242Z" fill="#23232D" />
            <path
                d="M93.527 33.5703C89.9565 33.5703 87.1465 36.3565 87.1465 39.9774C87.1465 43.6489 89.9523 46.4603 93.5789 46.4603C96.5909 46.4603 98.9717 44.7642 99.4024 42.1295H96.6176C96.3637 43.2953 95.1487 44.0543 93.5536 44.0543C91.5011 44.0543 90.0589 42.7117 89.805 40.6873H99.5034C99.5286 40.4067 99.5539 39.8778 99.5539 39.6239C99.5539 36.1783 96.8701 33.5703 93.527 33.5703ZM89.9565 38.6348C90.4125 36.9135 91.7046 35.9511 93.4007 35.9511C95.0968 35.9511 96.4142 37.0902 96.5657 38.6348H89.9565Z"
                fill="#23232D"
            />
            <path d="M101.218 46.2064H104.028V36.508H108.307V33.8242H101.218V46.2064Z" fill="#23232D" />
            <path
                d="M125.042 33.8242L122.459 41.877L119.522 33.8242H117.294L114.381 41.877L111.798 33.8242H108.76L113.242 46.2064H115.471L118.408 38.1789L121.345 46.2064H123.6L128.081 33.8242H125.042Z"
                fill="#23232D"
            />
            <path
                d="M134.247 33.5703C130.626 33.5703 127.84 36.3565 127.84 40.0237C127.84 43.6713 130.626 46.4561 134.247 46.4561C137.868 46.4561 140.679 43.6699 140.679 40.0237C140.679 36.3565 137.868 33.5703 134.247 33.5703ZM134.247 43.7513C132.171 43.7513 130.576 42.1295 130.576 40.0279C130.576 37.9011 132.171 36.2541 134.247 36.2541C136.298 36.2541 137.944 37.9011 137.944 40.0279C137.95 40.5164 137.859 41.0012 137.676 41.4541C137.493 41.907 137.221 42.3188 136.877 42.6654C136.533 43.0121 136.123 43.2867 135.671 43.4731C135.22 43.6596 134.735 43.7541 134.247 43.7513Z"
                fill="#23232D"
            />
            <path
                d="M146.037 30.5059H143.199V33.8238H140.845V36.5075H143.199V41.5468C143.199 44.6866 145.023 46.3588 147.758 46.3588C148.269 46.36 148.778 46.3087 149.278 46.2059V43.5979C148.979 43.6702 148.673 43.7041 148.366 43.6989C146.999 43.6989 146.037 43.0662 146.037 41.4963V36.5075H149.278V33.8238H146.037V30.5059Z"
                fill="#23232D"
            />
            <path
                d="M157.251 33.5703C155.632 33.5703 154.34 34.356 153.555 35.7224V33.8242H150.719V46.2064H153.555V39.015C153.732 37.3442 154.897 36.2808 156.568 36.2808C158.391 36.2808 159.582 37.5434 159.582 39.4962V46.2064H162.388V39.1413C162.392 35.6971 160.442 33.5703 157.251 33.5703Z"
                fill="#23232D"
            />
            <path
                d="M175.352 44.2052C175.203 44.205 175.056 44.2345 174.919 44.2921C174.782 44.3496 174.658 44.434 174.554 44.5403C174.45 44.6466 174.368 44.7727 174.314 44.911C174.259 45.0493 174.233 45.1972 174.237 45.3458C174.237 45.6447 174.355 45.9315 174.567 46.1429C174.778 46.3543 175.065 46.473 175.364 46.473C175.663 46.473 175.95 46.3543 176.161 46.1429C176.372 45.9315 176.491 45.6447 176.491 45.3458C176.494 45.1954 176.466 45.0461 176.409 44.9067C176.353 44.7673 176.269 44.6407 176.163 44.5343C176.057 44.4279 175.93 44.3439 175.791 44.2874C175.652 44.2309 175.502 44.2029 175.352 44.2052Z"
                fill="#23232D"
            />
            <path
                d="M186.149 33.7021C183.541 33.7021 181.566 35.07 180.705 37.3497V33.9505H179.591V50.5372H180.705V42.8646C181.566 45.0924 183.541 46.4603 186.149 46.4603C189.644 46.4603 192.404 43.6741 192.404 40.1037C192.404 36.5332 189.644 33.7021 186.149 33.7021ZM186.023 45.453C183.111 45.453 180.959 43.4777 180.705 40.6662V39.5523C180.959 36.7156 183.111 34.7403 186.023 34.7403C189.011 34.7403 191.315 37.0958 191.315 40.1093C191.315 43.0919 189.018 45.4474 186.023 45.4474V45.453Z"
                fill="#23232D"
            />
            <path d="M172.324 33.8242L169.007 42.2572L165.69 33.8242H162.651L167.564 45.5484L165.346 50.5372H168.385L175.363 33.8242H172.324Z" fill="#23232D" />
            <path d="M85.4925 13.793L82.1746 22.2259L78.8581 13.793H75.8193L80.7324 25.5172L78.5143 30.5059H81.5531L88.5312 13.793H85.4925Z" fill="#23232D" />
            <path
                d="M119.313 19.8872L124.976 26.1751H128.622L122.544 19.7441L128.243 13.7929H124.722L119.313 19.5575V8.44922H116.502V26.1751H119.313V19.8872Z"
                fill="#23232D"
            />
            <path
                d="M13.793 24.1367V46.206H35.8623L46.2074 35.8623V13.793H24.1381L13.793 24.1367ZM40.6897 40.6911H19.3106V19.3092H40.6897V40.6911Z"
                fill="#23232D"
            />
            <path d="M195.754 29.0117H194.64V46.2073H195.754V29.0117Z" fill="#23232D" />
        </svg>
    );
};
