import React from "react";
import {css} from "@panda/css";

import {IIssue} from "../api/get_issues";
import {Checklist} from "./common/Checklist";
import {DownloadButton} from "./common/DownloadButton";
import {SectionTitle} from "./common/SectionTitle";
import {FacebookIcon} from "./icons/FacebookIcon";

interface IProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
    issue: IIssue;
}

export const LatestEdition = (props: IProps) => {
    return (
        <section className={container} ref={props.refElem}>
            <SectionTitle title="Najnowszy numer" />

            <div className={contentContainer}>
                <div className={imgHolder}>
                    <img alt="Cover" className={editionImg} src={props.issue.cover_image} />
                    <DownloadButton href={props.issue.file} target="_blank">
                        Pobierz
                    </DownloadButton>
                </div>

                <div>
                    <Checklist items={props.issue.headlines} isDark />

                    <a className={fbHolder} href="https://www.facebook.com/groups/deweloper.i.marketing/" target="_blank">
                        <FacebookIcon className={css({marginRight: "20px"})} width={70} height={70} />

                        <p>
                            Chcesz wiedzieć więcej o marketingu w branży nieruchomości?
                            <br />
                            Zapisz się do naszej grupy na Facebooku <span className={css({color: "#ec4053"})}>„Deweloper&Marketing”</span>!
                        </p>
                    </a>
                </div>

                <a className={fbHolderMobile} href="https://www.facebook.com/groups/deweloper.i.marketing/" target="_blank">
                    <p>
                        Chcesz wiedzieć więcej o marketingu w branży nieruchomości? Zapisz się do naszej grupy na Facebooku
                        <span className={css({color: "#ec4053"})}> „Deweloper&Marketing”</span>!
                    </p>
                </a>
            </div>
        </section>
    );
};

const container = css({
    marginTop: "30px",
    marginBottom: "30px"
});

const imgHolder = css({
    position: "relative",
    paddingRight: "20px",

    "@media (max-width: 1025px)": {
        paddingRight: "0",
        marginTop: "20px",
        order: 2
    }
});

const editionImg = css({
    width: "346px",
    height: "472px",

    "@media (max-width: 1025px)": {
        width: "280px",
        height: "413px"
    }
});

const contentContainer = css({
    margin: "0 auto",
    padding: "0 20px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    maxWidth: "1400px",

    "@media (max-width: 1025px)": {
        flexDirection: "column"
    }
});

const fbHolder = css({
    display: "flex",
    borderRadius: "5px",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.1)",
    padding: "40px 20px",
    fontSize: "25px",
    fontWeight: "300",
    color: "#0c284f",
    marginTop: "40px",

    "&:hover": {
        textDecoration: "none"
    },

    "@media (max-width: 1025px)": {
        display: "none"
    }
});

const fbHolderMobile = css({
    borderRadius: "5px",
    boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.1)",
    padding: "20px 20px",
    fontSize: "20px",
    fontWeight: "300",
    color: "#0c284f",
    marginTop: "50px",
    order: 3,

    "&:hover": {
        textDecoration: "none"
    },

    "@media (min-width: 1025px)": {
        display: "none"
    }
});
