import React from "react";
import {css} from "@panda/css";
import {darken} from "polished";

import {Facebook2Icon} from "./icons/Facebook2Icon";
import {InstagramIcon} from "./icons/InstagramIcon";
import {LinkedInIcon} from "./icons/LinkedInIcon";
import {INavLink} from "./NavigationMobile";
import {RynekPierwotnyLogoIcon} from "./RynekPierwotnyLogoIcon";

import logoDM from "../images/logo_dm.svg";

interface IOwnProps {
    links: INavLink[];
    scrollTo: (duration: number, refElem: React.RefObject<HTMLTableSectionElement>) => (e: React.MouseEvent) => void;
}

export const NavigationDesktop = (props: IOwnProps) => {
    const {scrollTo} = props;

    return (
        <nav className={nav}>
            <a className={navLogo} href="/">
                <div className={logo} style={{background: `url("${logoDM}") no-repeat`}} />
            </a>

            {props.links.map((link, i) => (
                <div className={navLink} key={i} onClick={scrollTo(300, link.url)}>
                    {link.text}
                </div>
            ))}

            <div className={socialWrapper}>
                <div className={socialIconHolder}>
                    <a href="https://www.facebook.com/groups/deweloper.i.marketing/" target="_blank">
                        <Facebook2Icon height={30} width={30} />
                    </a>

                    <a href="https://www.instagram.com/rynekpierwotny/" target="_blank">
                        <InstagramIcon height={30} width={30} />
                    </a>

                    <a href="https://www.linkedin.com/company/3503462/ " target="_blank">
                        <LinkedInIcon height={30} width={30} />
                    </a>
                </div>

                <div className={logoWrapper}>
                    <p>Wydawcą magazynu jest portal</p>
                    <a href="https://rynekpierwotny.pl" target="_blank">
                        <RynekPierwotnyLogoIcon className={rpLogo} />
                    </a>
                </div>
            </div>
        </nav>
    );
};

const NAV_MAIN_COLOR = "#0c284f";

const logo = css({
    height: "50px",
    width: "150px",
    backgroundSize: "contain !important",

    "@media screen and (min-width: 1600px)": {
        height: "60px",
        width: "180px"
    },

    "@media screen and (min-width: 1920px)": {
        height: "90px",
        width: "263px",
        marginRight: "70px"
    }
});

const navLogo = css({
    display: "flex",
    alignItems: "center",
    padding: "10px",

    "@media screen and (min-width: 1024px)": {
        paddingLeft: "20px"
    }
});

const nav = css({
    display: "none",

    "@media screen and (min-width: 1024px)": {
        height: "100px",
        display: "flex",
        alignContent: "center",
        justifyContent: "flex-start",
        position: "fixed",
        backgroundColor: "#fff",
        color: NAV_MAIN_COLOR,
        top: 0,
        left: 0,
        right: 0,
        zIndex: "120"
    },

    "@media screen and (min-width: 1600px)": {
        justifyContent: "center",
        height: "140px"
    }
});

const navLink = css({
    transition: "background-color 300ms ease-out",
    padding: "12px",
    marginRight: "12px",
    textDecoration: "none",
    textTransform: "uppercase",
    color: NAV_MAIN_COLOR,
    fontSize: "20px",
    fontWeight: "300",
    letterSpacing: "0.42px",
    lineHeight: "37px",
    position: "relative",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "@media screen and (min-width: 1600px)": {
        fontSize: "25px",
        padding: "20px",
        marginRight: "20px"
    },

    "&:after": {
        content: '""',
        position: "absolute",
        right: "-20px",
        top: "50%",
        boxSizing: "border-box",
        height: "2px",
        width: "26px",
        border: "1px solid #757575",
        transform: "rotate(90deg)"
    },

    "&:hover": {
        color: darken(0.05, NAV_MAIN_COLOR),
        textDecoration: "none"
    },

    "&.active": {
        color: darken(0.05, NAV_MAIN_COLOR)
    },

    "&:last-of-type:after": {
        content: "none"
    }
});

const socialWrapper = css({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& p": {
        whiteSpace: "nowrap",
        fontSize: "18px",

        "@media screen and (min-width: 1600px)": {
            lineHeight: "36px",
            fontSize: "24px"
        }
    }
});

const socialIconHolder = css({
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    marginLeft: "10px",

    "& .svg-icon": {
        marginRight: "20px"
    }
});

const logoWrapper = css({
    display: "none",

    "@media screen and (min-width: 1280px)": {
        display: "block",
        borderLeft: "2px solid #bababa",
        paddingLeft: "26px"
    },

    "& p": {
        marginBottom: "0px"
    }
});

const rpLogo = css({
    marginTop: "5px",

    "@media screen and (min-width: 1600px)": {
        height: "47px",
        width: "220px"
    }
});
