import React from "react";
import {css} from "@panda/css";

import {IIssue} from "../api/get_issues";
import {SectionTitle} from "./common/SectionTitle";

interface IProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
    issue: IIssue | null;
}

export const AboutIssue = (props: IProps) => {
    return (
        <section className={aboutIssueSection} ref={props.refElem}>
            <SectionTitle title="O Kwartalniku" />

            <div className={container}>
                <div className={contentContainer}>
                    {props.issue && (
                        <>
                            <img className={desktopIssueImage} src={props.issue.main_page_image} />

                            <img className={mobileIssueImage} src={props.issue.main_page_image} width="100%" height="auto" alt={props.issue.number} />
                        </>
                    )}

                    <div className={textContainer}>
                        <p className={paragraphGray}>
                            Nasze czasopismo tworzą eksperci portalu RynekPierwotny.pl, którzy pomagają prawie 1000 firm deweloperskich wypromować inwestycje
                            mieszkaniowe.
                        </p>

                        <div className={navyDashedBox}>
                            Jeśli jesteś zainteresowany współpracą z nami lub reklamą w kwartalniku napisz:
                            <a href="mailto:pr@rynekpierwotny.pl">pr@rynekpierwotny.pl</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const aboutIssueSection = css({
    marginTop: "10px",

    "@media screen and (min-width: 1024px)": {
        position: "relative",
        marginTop: "75px",
        boxShadow: "0 4px 10px -2px rgba(0, 0, 0, 0.1)",
        paddingBottom: "20px"
    }
});

const container = css({
    "@media screen and (min-width: 1024px)": {
        height: "300px",
        maxWidth: "1400px",
        margin: "0 auto"
    },

    "@media screen and (min-width: 1280px)": {
        height: "350px"
    },

    "@media screen and (min-width: 1600px)": {
        height: "415px"
    }
});

const desktopIssueImage = css({
    display: "none",
    position: "absolute",
    right: "0",
    top: "72px",
    maxWidth: "691px",
    clipPath: "polygon(0 0, 100% 1%, 100% 100%, 17% 100%)",

    "@media screen and (min-width: 1024px)": {
        display: "block",
        maxHeight: "298px"
    },

    "@media screen and (min-width: 1280px)": {
        display: "block",
        maxHeight: "338px"
    },

    "@media screen and (min-width: 1600px)": {
        display: "block",
        maxHeight: "380px"
    }
});

const mobileIssueImage = css({
    height: "auto",
    width: "100%",
    margin: "10px auto",

    "@media screen and (min-width: 1024px)": {
        display: "none"
    }
});

const contentContainer = css({
    maxWidth: "1400px",
    margin: "30px auto",
    padding: "0 20px"
});

const textContainer = css({
    width: "100%",

    "@media screen and (min-width: 1024px)": {
        width: "50%"
    }
});

const paragraphGray = css({
    fontSize: "18px",
    fontWeight: "300",
    lineHeight: "26px",
    color: "#757575",

    "@media screen and (min-width: 1024px)": {
        fontSize: "22px",
        lineHeight: "30px",
        marginBottom: "20px"
    },

    "@media screen and (min-width: 1280px)": {
        fontSize: "26px",
        lineHeight: "35px",
        marginBottom: "30px"
    },

    "@media screen and (min-width: 1600px)": {
        fontSize: "30px",
        lineHeight: "45px",
        marginBottom: "30px"
    }
});

const navyDashedBox = css({
    width: "100%",
    height: "auto",
    border: "4px dashed #002664",
    color: "#0c284f",
    fontSize: "20px",
    fontWeight: "300",
    lineHeight: "30px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "20px 17px",

    "@media screen and (min-width: 1024px)": {
        padding: "30px 35px",
        fontSize: "25px",
        lineHeight: "40px"
    },

    "@media screen and (min-width: 1280px)": {
        padding: "30px 50px",
        fontSize: "30px",
        lineHeight: "45px"
    },

    "@media screen and (min-width: 1600px)": {
        height: "215px",
        width: "748px",
        padding: "30px 100px",
        fontSize: "30px",
        lineHeight: "45px"
    },

    "& a": {
        color: "#ec4053",
        fontWeight: "500",
        fontSize: "22px",
        textDecoration: "none",

        "@media screen and (min-width: 1024px)": {
            fontSize: "35px"
        }
    }
});
