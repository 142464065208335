import React from "react";
import Slider, {Settings} from "react-slick";
import {css, cx} from "@panda/css";

import {SliderArrow} from "./SliderArrow";

interface IProps {
    children: React.ReactNode[];
    sliderConfig?: Settings;
}

export const BasicSlider = (props: IProps) => {
    const defaultSettings: Settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className={cx(sliderHolder, "slider-holder")}>
            <Slider
                {...(props.sliderConfig || defaultSettings)}
                className="slider-main-slide-holder"
                nextArrow={<SliderArrow orientation="right" />}
                prevArrow={<SliderArrow orientation="left" />}
            >
                {props.children.map((slide, index) => {
                    return (
                        <div key={index} className="slide">
                            {slide}
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

const sliderHolder = css({
    height: "100%",
    overflowY: "hidden",

    "& .slick-slider": {
        height: "100%",

        "& .slick-list": {
            height: "100%"
        },

        "& .slick-track": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100%",

            "& .slick-slide": {
                float: "none",
                display: "flex",
                alignItems: "center",

                "& .slide": {
                    "& img": {
                        display: "block",
                        maxWidth: "100%",
                        height: "auto"
                    }
                }
            },

            "& .slick-current": {
                "& > div": {
                    width: "100%"
                }
            }
        },

        "&.slick-arrow-sm": {
            "&.slick-prev, &.slick-next": {
                zIndex: 1,
                transform: "translate(0, -70%)",

                "&:before": {
                    fontSize: "2rem",
                    position: "relative",
                    top: "0.2rem"
                }
            },

            "&.slick-next": {
                "&:before": {
                    marginLeft: "-3px"
                }
            },

            "&.slick-prev": {
                "&:before": {
                    marginRight: "-3px"
                }
            }
        },

        "&.gradient-figcaption": {
            background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 50%)",
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            color: "#fff",
            padding: "30px 10px 10px"
        }
    },

    "& .slick-prev, & .slick-next": {
        position: "absolute",
        zIndex: 1,
        transform: "translate(0, -70%)",

        "&:before": {
            fontSize: "3rem",
            position: "relative",
            top: "0.2rem"
        }
    },

    "&.slick-prev": {
        left: 0,
        bottom: "50%"
    },

    "&.slick-next": {
        right: 0,
        bottom: "50%"
    }
});
