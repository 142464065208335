import React from "react";
import {css} from "@panda/css";

import {IPartner} from "../api/get_partners";
import {Checklist} from "./common/Checklist";
import {Title} from "./common/Title";

import hero from "../images/hero.jpg";

const HERO_CHECKLIST = [
    "Inspirujące wywiady z gośćmi specjalnymi",
    "Najświeższe trendy marketingowe w branży nieruchomości",
    "Case studies SEO & SEM oraz social media",
    "Przegląd nowości z zakresu digital marketingu",
    "Zagadnienia i porady prawne"
];

interface IProps {
    partners: IPartner[] | null;
}

export const HeroSection = (props: IProps) => {
    const essentialPartners = (props.partners || []).filter((partner) => partner.is_essential);
    const nonEssentialPartners = (props.partners || []).filter((partner) => !partner.is_essential);

    return (
        <section className={heroContainer} style={{backgroundImage: `url("${hero}")`}}>
            <div className={imgColorMultiply} />

            <div className={contentWrapper}>
                <div className={contentWidth}>
                    <Title>W KAŻDYM NUMERZE ZNAJDZIESZ:</Title>

                    <Checklist items={HERO_CHECKLIST} />

                    {props.partners && (
                        <div className={patrons}>
                            <p>Patron merytoryczny:</p>

                            {essentialPartners.map((item) => {
                                return <div className={patronImg} style={{backgroundImage: `url(${item.logo})`}} key={item.name} />;
                            })}

                            <p>Partnerzy:</p>

                            {nonEssentialPartners.map((item) => {
                                return <div className={patronImg} style={{backgroundImage: `url(${item.logo})`}} key={item.name} />;
                            })}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

const heroContainer = css({
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "720px",
    margin: "100px 0 30px 0",

    "@media (max-width: 1366px)": {
        marginTop: "100px"
    },

    "@media (max-width: 1025px)": {
        height: "600px",
        marginTop: "80px"
    }
});

const imgColorMultiply = css({
    position: "absolute",
    height: "100%",
    backgroundColor: "rgba(20, 41, 77, 0.95)",
    mixBlendMode: "multiply",
    transform: "skew(20deg)",
    marginLeft: "-200px",
    width: "60%",

    "@media (max-width: 1200px)": {
        width: "65%"
    },

    "@media (max-width: 1100px)": {
        width: "70%"
    },

    "@media (max-width: 1025px)": {
        height: "100%",
        width: "100%",
        marginLeft: "0",
        transform: "none"
    }
});

const contentWrapper = css({
    position: "relative",
    zIndex: "1",
    color: "#fff",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
    padding: "0 20px",
    maxWidth: "1400px"
});

const contentWidth = css({
    maxWidth: "50%",

    "@media (max-width: 1025px)": {
        maxWidth: "100%"
    }
});

const patrons = css({
    position: "absolute",
    zIndex: "2",
    backgroundColor: "#fff",
    color: "#000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "260px",
    boxShadow: "0 -1px 8px 0 rgba(0, 0, 0, 0.29)",
    padding: "25px 35px",
    borderRadius: "5px",
    bottom: "-50px",
    right: "150px",

    "& p": {
        fontSize: "1.6rem",
        marginBottom: "20px"
    },

    "& div:last-of-type": {
        marginBottom: "0"
    },

    "@media (max-width: 1100px)": {
        right: "70px"
    },

    "@media (max-width: 1025px)": {
        display: "none"
    }
});

const patronImg = css({
    width: "100%",
    height: "50px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
    marginBottom: "15px"
});
