import React from "react";
import {css} from "@panda/css";

import {IArticle} from "../api/get_articles";
import {SectionTitle} from "./common/SectionTitle";
import {ArticleBox} from "./ArticleBox";

interface IProps {
    refElem: React.RefObject<HTMLTableSectionElement>;
    articles: IArticle[];
}

export const Articles = (props: IProps) => {
    return (
        <section className={container} ref={props.refElem}>
            <SectionTitle title="Artykuły" />

            <div className={articlesHolder}>
                {props.articles.map((item, index) => {
                    if (index === props.articles.length) {
                        return <ArticleBox key={index} img={item.image} title={item.title} lead={item.description} articleLink={item.url} />;
                    }

                    return (
                        <div className={articleWrapper} key={index}>
                            <ArticleBox img={item.image} title={item.title} lead={item.description} articleLink={item.url} />
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

const container = css({
    marginTop: "30px",
    marginBottom: "30px"
});

const articlesHolder = css({
    margin: "0 auto",
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "1400px",

    "@media (max-width: 980px)": {
        flexDirection: "column"
    }
});

const articleWrapper = css({
    marginRight: "30px",

    "@media (max-width: 980px)": {
        marginRight: "0",
        marginBottom: "20px"
    }
});
