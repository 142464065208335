import React from "react";
import {css} from "@panda/css";

import {Title} from "./Title";

interface IProps {
    title: string;
    lineColor?: string;
}

export const SectionTitle = (props: IProps) => {
    return (
        <div className={titleHolder}>
            <div className={titleLine} style={{borderBottomColor: props.lineColor || "#0C284F"}} />

            <Title className={titleWrapper} style={{color: props.lineColor || "#0C284F"}}>
                {props.title}
            </Title>

            <div className={titleLine} style={{borderBottomColor: props.lineColor || "#0C284F"}} />
        </div>
    );
};

const titleHolder = css({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px"
});

const titleLine = css({
    flexGrow: 1,
    flexShrink: 1,
    height: "1px",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px"
});

const titleWrapper = css.raw({
    flexGrow: "0",
    flexShrink: "0",
    textTransform: "uppercase",
    marginBottom: "0 !important",
    padding: "0 10px"
});
