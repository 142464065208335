import React from "react";
import {css, cx} from "@panda/css";

interface IProps {
    onClick: () => void;
    opened: boolean;
}

export const HamburgerTrigger = (props: IProps) => {
    const triggerClassName = cx(navTriggerWrapper, props.opened && "opened", "menu-trigger");

    return (
        <div className={triggerClassName} onClick={props.onClick}>
            <div>
                <span />
                <span />
                <span />
            </div>
        </div>
    );
};

export const navTriggerWrapper = css({
    transition: "all 0.3s ease-in-out",
    zIndex: 130,
    padding: "10px",
    zoom: 1.5,

    "@media screen and (min-width: 1025px)": {
        display: "none"
    },

    "& > div": {
        position: "relative",
        width: "19px",
        height: "14px"
    },

    "& span": {
        position: "absolute",
        width: "100%",
        height: "2px",
        backgroundColor: "#0c284f",
        transition: "all 0.3s ease-in-out",
        borderRadius: "4px",

        "&:nth-of-type(1)": {
            top: 0,
            transformOrigin: "50%"
        },

        "&:nth-of-type(2)": {
            top: "6px",
            right: 0
        },

        "&:nth-of-type(3)": {
            top: "12px",
            right: 0,
            transformOrigin: "50%"
        }
    },

    "&.opened": {
        animation: "smallBig 0.6s forwards",
        position: "absolute",
        top: "10px",
        right: "2px",

        "& span": {
            transitionDelay: "0.2s",
            backgroundColor: "#fff",

            "&:nth-of-type(1)": {
                transform: "translateY(6px) rotate(45deg)"
            },

            "&:nth-of-type(2)": {
                opacity: 0
            },

            "&:nth-of-type(3)": {
                transform: "translateY(-6px) rotate(-45deg)",
                width: "100%"
            }
        }
    }
});
